<template>
  <!-- 结算管理 - 预付款记录管理 -->
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">预付款列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 6rem">班级编码:</span>
              <el-input
                v-model="searchData.projectCode"
                clearable
                placeholder="请输入班级编码"
                size="small"
              ></el-input>
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="searchData.projectName"
                clearable
                placeholder="请输入班级名称"
                size="small"
              ></el-input>
            </div>
            <div title="结算单号" class="searchboxItem ci-full">
              <span class="itemLabel">结算单号:</span>
              <el-input
                v-model="searchData.billNo"
                clearable
                placeholder="请输入结算单编号"
                size="small"
              ></el-input>
            </div>
            <div class="df">
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >

              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                style="margin-right: 22px"
                round
                @click="advanceClassExport()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              ><el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="80px"
              /><el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                minWidth="160"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                minWidth="120"
              />
              <el-table-column
                label="结算单号"
                align="left"
                show-overflow-tooltip
                prop="billNo"
              />
               <el-table-column
                label="结算金额"
                align="left"
                show-overflow-tooltip
                prop="settlement"
              />
              <el-table-column
                label="结算日期"
                align="left"
                show-overflow-tooltip
                prop="billRecycleDate"
              >
                <template slot-scope="scope">{{
                  scope.row.billRecycleDate | momentDate
                }}</template>
              </el-table-column>

              <el-table-column
                label="培训时间"
                align="center"
                show-overflow-tooltip
                prop="trainDate"
                width="160"
                />
              <el-table-column
                label="结业人数"
                align="left"
                show-overflow-tooltip
                prop="graduationNum"
              />
              <el-table-column
                label="预扣款金额"
                align="left"
                show-overflow-tooltip
                prop="advancecharge"
              />
             
             
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "InvoicingCompanyManagement",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 检索数据集
      searchData: {
        // 班级编码
        projectCode: "",
        // 班级名称
        projectName: "",
        // 结算单号
        billNo: "",
      },
      compId:this.$route.query.compId,
      identtity:this.$route.query.identtity
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {},
  methods: {
    // 初始化获取列表
    getData(pageNum = 1) {
      const params = {
        // 当前页数
        pageNum: pageNum,
        // 每页条数
        pageSize: this.pageSize,
        compId:this.compId
      };
      if (this.searchData.projectCode) {
        params.projectCode = this.searchData.projectCode;
      }
      if (this.searchData.projectName) {
        params.projectName = this.searchData.projectName;
      }
      if (this.searchData.billNo) {
        params.billNo = this.searchData.billNo;
      }
      this.doFetch({
        url: "/biz/advancecharge/advancechargeProjectList",
        params,
        pageNum,
      });
    },
    
// 
//导出
    advanceClassExport() {
      const params = {
        compId: this.compId,
        identtity: this.identtity, //10财务
      };
        if (this.searchData.projectCode) {
        params.projectCode = this.searchData.projectCode;
      }
      if (this.searchData.projectName) {
        params.projectName = this.searchData.projectName;
      }
      if (this.searchData.billNo) {
        params.billNo = this.searchData.billNo;
      }
      this.$post("/biz/advancecharge/exportCompAdvancechargeProject", params)
        .then((res) => {
          if (res.status == "0") {
            let list = res.data;
            if (!this.downloadItems.includes(list.taskId)) {
              this.$store.dispatch("pushDownloadItems", list.taskId);
            } else {
              this.$message.warning(
                "[" + list.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {
          return;
        });
    },
     getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + .675 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.faceStudentstatistics {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;

    .el-cascader {
      height: 32px !important;
    }
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.bh /deep/ .el-textarea__inner {
  height: 200px;
}
.exportdialog {
  .el-dialog__title {
    font-size: 16px;
  }
  .export-box {
    width: 100%;
    margin-bottom: 20px;
    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;
      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }
    > div {
      padding-left: 30px;
    }
    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}
</style>
<style lang="less">
.exportdialog {
  .upload-workers {
    height: 30px;
    .el-upload {
      height: 30px !important;
      width: 97px;
      border: none !important;
      .el-button {
        padding: 5px 0;
      }
    }
  }
}
</style>
